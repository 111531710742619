import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import greenPlus from '@frontend/jetlend-assets/icons/icon-plus--green-rounded.svg';
import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';

import styles from './addUserButton.module.scss';

export interface IProps {
    text: string;
    onCreate?: (type: string) => void;
    href?: string;
}

export const AddUserButton = (props: IProps) => {
    const {
        onCreate,
        text,
        href,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const buttonsData = useMemo(() => [
        {
            title: 'Инвестор',
            onClick: () => onCreate && onCreate('investor'),
            href,
        },
        {
            title: 'Заёмщик',
            onClick: () => onCreate && onCreate('borrower'),
            href,
        },
        {
            title: 'Партнер',
            onClick: () => onCreate && onCreate('partner'),
            href,
        },
    ], [onCreate, href]);

    const didCreateButtonClicked = useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, []);

    const didMenuClosed = useCallback(() => setIsOpen(false), []);

    return (
        <DropdownActions
            button={(
                <DropdownActions.Button onClick={didCreateButtonClicked}>
                    <div className={styles['text__container']}>
                        <img src={getAssetSrc(greenPlus)} alt={text} />
                        <div className={styles['text']}>{text}</div>
                    </div>
                </DropdownActions.Button>
            )}
            isOpen={isOpen}
            onClose={didMenuClosed}
            placement="bottom"
        >
            {buttonsData.map(item =>
                onCreate
                    ? (
                        <DropdownActions.Button
                            onClick={item.onClick}
                            key={item.title}
                            className={styles['user-type__button']}
                        >
                            {item.title}
                        </DropdownActions.Button>
                    )
                    : (
                        <DropdownActions.Link
                            href={item.href}
                            key={item.title}
                            className={styles['user-type__button']}
                        >
                            {item.title}
                        </DropdownActions.Link>
                    )
            )}
        </DropdownActions>
    );
};