import React, { useCallback } from 'react';
import iconUser from '@frontend/jetlend-assets/icons/icon--user.svg';
import iconChevron from '@frontend/jetlend-assets/icons/icon-chevron--down.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';

import styles from './selectUserButton.module.scss';

import Tooltip from '../../ui/TooltipIcon/Tooltip';

export interface IProps extends React.PropsWithChildren {
    name: React.ReactNode|JSX.Element;
    sub?: React.ReactNode|JSX.Element;
    image?: string;

    disabled?: boolean;
    loading?: boolean;
    isOpen?: boolean;
    users: {
        borrowers: any[];
        investors: any[];
        partners: any[];
    };

    clientItemSwitchUrl?: (type: string, id: string) => string;
    onFetchUser?: () => void;
    onCreate?: (type: string) => void;
    onClick?: () => void;
    onClose?: () => void;
}

const SelectUserButton: React.FC<IProps> = props => {
    const didButtonClicked = useCallback(() => {
        if (props.disabled) {
            return;
        }

        if (props.isOpen && props.onClose) {
            props.onClose();
            return;
        }

        if (!props.isOpen && props.onClick) {
            props.onClick();
        }
    }, [props.onClick, props.disabled, props.isOpen]);

    if (props.loading) {
        return;
    }

    return (
        <Tooltip title="Авторизованный пользователь">
            <div className={styles['container']}>
                <button className={styles['button']} onClick={didButtonClicked}>
                    <div className={styles['button__wrapper']}>
                        <img
                            className={styles['button__icon']}
                            src={getAssetSrc(props.image ?? iconUser)}
                            alt=""
                        />
                    </div>

                    <div className={styles['button__content']}>
                        <div className={styles['button__name']}>
                            {props.name}
                            <img src={getAssetSrc(iconChevron)} alt="" />
                        </div>
                        {props.sub && (
                            <div className={styles['button__sub']}>
                                {props.sub}
                            </div>
                        )}
                    </div>

                </button>
                {props.children && React.Children.count(props.children) > 0 && (
                    <div className={styles['button__tail']}>
                        {props.children}
                    </div>
                )}
            </div>
        </Tooltip>
    );
};

export default SelectUserButton;