'use client';

import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import { connectFunctionalComponent } from '@frontend/jetlend-core/src/ducks/connect';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React, { useCallback } from 'react';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';

import styles from './RegistrationButton.module.scss';

type Props = {
    startRegistration: typeof startRegistration;
};

const RegistrationButton: React.FC<Props> = props => {
    const didInvestorStartClicked = useCallback(() => props.startRegistration(ClientType.Investor), []);
    const didBorrowerStartClicked = useCallback(() => props.startRegistration(ClientType.Borrower), []);
    const didPartnerStartClicked = useCallback(() => props.startRegistration(ClientType.Partner), []);

    return (
        <DropdownActions
            placement="bottom"
            size="small"
            hover
            withoutArrow={false}
            button={(
                <Button
                    text="Начать"
                    type="primary"
                    noSpacing
                    width={130}
                />
            )}
        >
            <DropdownActions.Button onClick={didInvestorStartClicked} className={styles['button']}>
                Инвестор
            </DropdownActions.Button>
            <DropdownActions.Button onClick={didBorrowerStartClicked} className={styles['button']}>
                Предприниматель
            </DropdownActions.Button>
            <DropdownActions.Button onClick={didPartnerStartClicked} className={styles['button']}>
                Партнер
            </DropdownActions.Button>
        </DropdownActions>
    );
};

export default connectFunctionalComponent(RegistrationButton, {
    dispatch: {
        startRegistration,
    },
}) as React.FC;
