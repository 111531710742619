'use client';

import React, {
    useEffect,
    useState,
} from 'react';
import ResponsiveMenu, { ResponsiveMenuButton } from '@frontend/jetlend-web-ui/src/components/ResponsiveMenu/ResponsiveMenu';
import { usePathname } from 'next/navigation';
import ConnectedToastsList from '@frontend/jetlend-web-ui/src/components/Toasts/ConnectedToastsList';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import Image from '../../../server/Image/Image';
import LoginButton from '../../../server/common/LoginButton/LoginButton';
import RegistrationButton from '../../../server/common/RegistrationButton/RegistrationButton';
import { IPagesMenuApiModel } from '@app/models/pages/pages';
import { capitalize } from '@app/utils/capitalize';
import ConnectedSelectUserButton from '../ConnectedSelectUserButton/ConnectedSelectUserButton';
import ConnectedLogoutButton from '../ConnectedLogoutButton/ConnectedLogoutButton';
import { accountInfoHandler } from '@app/ducks/common/account';

export interface IProps {
    /**
     * Список элементов навигации
     */
    navigation: IPagesMenuApiModel[];
}

const getFormattedPathname = (pathname: string) => {
    const firstPart = pathname.split('/')[1];

    return `/${firstPart}/`;
};

/**
 * Компонент хедера страницы
 */
export default function LayoutHeader ({
    navigation,
}: IProps) {
    const pathname = usePathname();
    const [v, setV] = useState(0);
    const [account] = useSimpleApiHandler(accountInfoHandler);

    // Upgrade version when pathname provided to rerender items and highlight it
    useEffect(() => {
        setV(v => v + 1);
    }, [ pathname ]);


    return (
        <>
            <ResponsiveMenu
                fixed
                logo={(
                    <a href="/">
                        {/* New year Logo parameters width=153 height=47 */}
                        <Image src="/assets/logo.png" width={153} height={35} unoptimized alt="" />
                    </a>
                )}
                smallLogo={(
                    <a href="/">
                        {/* New year Logo parameters width=100 height=31 */}
                        <Image src="/assets/logo.png" width={100} height={23} unoptimized alt="" />
                    </a>
                )}
                actions={account && account.user_id
                    ? (
                        <>
                            <ConnectedSelectUserButton account={account} />
                            <ConnectedLogoutButton account={account} />
                        </>
                    )
                    : (
                        <>
                            <LoginButton />
                            <RegistrationButton />
                        </>
                    )
                }
            >
                {navigation && navigation.map(item => (
                    <ResponsiveMenuButton
                        key={`${item.url}--${v}`}
                        href={item.url}
                        title={capitalize(item.title)}
                        active={item.url === getFormattedPathname(pathname)}
                    />
                ))}
            </ResponsiveMenu>
            <ConnectedToastsList />
        </>
    );
};
