import {
    ApiDataResponse,
    BaseApiResponse,
} from '@frontend/jetlend-core/src/models/api';
import {
    IAchievementItemApiModel,
    IAchievementSeenPayload,
    ICourseBlockPassPayload,
    ICourseInformationApiModel,
    ICourseStateApiModel,
    IUserCoursesItemApiModel,
} from '@app/models/academy/course';
import {
    apiGetRequest,
    apiPostRequest,
} from '@app/services/client/api';

export async function apiGetCourseInformationHandler(course_slug: string): Promise<ApiDataResponse<ICourseInformationApiModel>> {
    return apiGetRequest(`/api/academy/courses/${course_slug}`);
}

export async function apiGetCourseStateHandler(course_id: number): Promise<ApiDataResponse<ICourseStateApiModel>> {
    return apiGetRequest(`~/invest/api/academy/courses/${course_id}/state`);
}

export async function apiGetRecommendedCoursesHandler(): Promise<ApiDataResponse<ICourseInformationApiModel[]>> {
    // return testResponse(MOCK_COURSES);
    return apiGetRequest('~/invest/api/academy/courses/recommended');
}

export async function apiGetAchievementsHandler(): Promise<ApiDataResponse<IAchievementItemApiModel[]>> {
    // return testResponse(MOCK_ACHIEVEMENTS);
    return apiGetRequest('~/invest/api/academy/achievements');
}

export async function apiGetAllUserCoursesHandler(): Promise<ApiDataResponse<IUserCoursesItemApiModel[]>> {
    // return testResponse(MOCK_USER_COURSES);
    return apiGetRequest('~/invest/api/academy/courses/state');
}

export async function apiPostMarkCourseBlockAsPassed({
    course_id,
    block_id,
}: ICourseBlockPassPayload): Promise<BaseApiResponse> {
    return apiPostRequest(`~/invest/api/academy/courses/${course_id}/blocks/${block_id}/pass`, {});
}

export async function apiPostAchievementSeenHandler(payload: IAchievementSeenPayload): Promise<BaseApiResponse> {
    // return testResponse({});
    return apiPostRequest('~/invest/api/academy/achievements/seen', payload);
}