import SelectUserButton from '@frontend/jetlend-web-ui/src/components/SelectUserButton/SelectUserButton';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    isMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import { UserButton } from '@frontend/jetlend-web-ui/src/components/UserButton/UserButton';
import AccountTypeBadge from '@frontend/jetlend-web-ui/src/components/AccountTypeBadge/AccountTypeBadge';
import { AddUserButton } from '@frontend/jetlend-web-ui/src/components/AddUserButton/AddUserButton';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import arrowLeft from '@frontend/jetlend-assets/icons/icon--arrow-left-light-gray.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import useModalHandler from '@frontend/jetlend-core/src/hooks/useModalHandler';
import useFormSubmitter from '@frontend/jetlend-core/src/hooks/useFormSubmitter';
import {
    ClientType,
    CLIENT_TYPE_CABINET_TARIFF_URLS,
    CLIENT_TYPE_CABINET_URLS,
    getClientTitle,
} from '@app/models/common/common';
import {
    fetchUser,
    getUsers,
    userModalHandler,
} from '@app/ducks/common/account';

import styles from './connectedSelectUserButton.module.scss';
import { IAccountInfoApiModel } from '@app/models/common/account';

const ACCOUNT_NAMES = {
    [ClientType.Investor]: 'Инвестор',
    [ClientType.Borrower]: 'Заемщик',
    [ClientType.Partner]: 'Партнер',
};

export interface IProps {
    /**
     * Объект с информацией об аккаунте
     */
    account: IAccountInfoApiModel;
}

export default function ConnectedSelectUserButton ({ account }: IProps) {
    const { width: screenWidth } = useWindowSize();
    const dispatch = useDispatch();
    const users = useSelector(getUsers);
    const [ isOpen, open, close ] = useModalHandler(userModalHandler);
    const [ submit ] = useFormSubmitter(userModalHandler);


    const clientType = ACCOUNT_NAMES[account?.client_type];
    const linkTitle = `Личный кабинет ${getClientTitle(clientType)}`;

    const didUserSwitched = useCallback((type: ClientType, id: number) => {
        submit({
            client_id: id,
            client_type: type,
        });

        close();
    }, [ submit, close ]);

    const didModalOpened = useCallback(() => open(), [ open ]);
    const didModalClosed = useCallback(() => close(), [ close ]);

    const accountName = useMemo(() => {
        if (!account?.name) {
            return ACCOUNT_NAMES[account?.client_type];
        }

        const { name } = account;
        if (isTablet(screenWidth) || isMobile(screenWidth)) {
            const parts = name.split(' ');
            if (parts.length === 3) {
                // Пример: Иванов И.И.
                return `${parts[0]} ${parts[1].charAt(0)}. ${parts[2].charAt(0)}.`;
            }
        }

        return name;
    }, [ account?.name, screenWidth ]);

    useEffect(() => {
        dispatch(fetchUser());
    }, [ dispatch ]);

    return (
        <DropdownActions
            button={(
                <SelectUserButton
                    name={accountName}
                    loading={!accountName}
                    users={users}
                    onClick={didModalOpened}
                    onClose={didModalClosed}
                    isOpen={isOpen}
                >
                    <AccountTypeBadge href={CLIENT_TYPE_CABINET_TARIFF_URLS[account?.client_type]} type={account?.account_type} />
                </SelectUserButton>
            )}
            isOpen={isOpen}
            onClose={didModalClosed}
        >
            {account?.client_type && (
                <DropdownActions.Link className={styles['link']} href={CLIENT_TYPE_CABINET_URLS[account?.client_type]}>
                    <div>{linkTitle}</div>
                    <img className={styles['arrow']} src={getAssetSrc(arrowLeft)} alt="" />
                </DropdownActions.Link>
            )}
            {users?.investors?.length > 0 && (
                <>
                    <DropdownActions.Section title="Инвесторы" />
                    {users.investors.map(item => (
                        <UserButton
                            key={item.id}
                            type="investor"
                            id={item.id}
                            name={item.name}
                            onSwitchUser={didUserSwitched}
                        />
                    ))}
                </>
            )}
            {users?.borrowers?.length > 0 && (
                <>
                    <DropdownActions.Section title="Заемщики" />
                    {users.borrowers.map(item => (
                        <UserButton
                            key={item.id}
                            type="borrower"
                            id={item.id}
                            name={item.name}
                            onSwitchUser={didUserSwitched}
                        />
                    ))}
                </>
            )}
            {users?.partners?.length > 0 && (
                <>
                    <DropdownActions.Section title="Партнеры" />
                    {users.partners.map(item => (
                        <UserButton
                            key={item.id}
                            type="partner"
                            id={item.id}
                            name={item.name}
                            onSwitchUser={didUserSwitched}
                        />
                    ))}
                </>
            )}
            <AddUserButton text="Добавить аккаунт" />
        </DropdownActions>
    );
};