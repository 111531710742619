'use client';

import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React from 'react';
import { usePathname } from 'next/navigation';

import styles from './LoginButton.module.scss';

const LoginButton = () => {
    const path = usePathname();
    const investLoginHref = `/invest/login${path?.includes('/academy') ? '/?return_uri=/academy' : ''}`;

    return (
        <DropdownActions
            placement="bottom"
            size="small"
            hover
            withoutArrow={false}
            button={(
                <Button
                    text="Войти"
                    type="secondary"
                    noSpacing
                    width={130}
                />
            )}
        >
            <DropdownActions.Link href={investLoginHref} noFollow className={styles['link']}>
                Инвестор
            </DropdownActions.Link>
            <DropdownActions.Link href="/lend/login" noFollow className={styles['link']}>
                Предприниматель
            </DropdownActions.Link>
            <DropdownActions.Link href="/partner/login" noFollow className={styles['link']}>
                Партнер
            </DropdownActions.Link>
        </DropdownActions>
    );
};

export default LoginButton;