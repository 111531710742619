import React from 'react';
import HeaderButton from '@frontend/jetlend-web-ui/src/layout/HeaderButton';
import iconLogout from '@frontend/jetlend-assets/icons/icon--logout.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import connectToForm from '@frontend/jetlend-core/src/ducks/connectToForm';
import { BaseApiResponse } from '@frontend/jetlend-core/src/models/api';
import { commonLogoutUserFormHandler } from '@app/ducks/common/login';
import { IAccountInfoApiModel } from '@app/models/common/account';

export interface IProps {
    /**
     * Объект с информацией об аккаунте
     */
    account: IAccountInfoApiModel;
}


export const submitWithAccount = commonLogoutUserFormHandler.dispatchExt((values, props: IProps) => ({
    ...values,
    clientType: props.account.client_type,
}));

export default connectToForm<IProps, BaseApiResponse>(function ConnectedLogoutButton({ handleSubmit }) {

    return <HeaderButton hint="Выход" type="button" icon={getAssetSrc(iconLogout)} onClick={handleSubmit(submitWithAccount)} />;
}, commonLogoutUserFormHandler);